import React from 'react'
import Helmet from 'react-helmet'
import { usePageContext } from 'core/helpers/pageContext'
import { getPageSocialMeta, getPageMeta } from 'core/helpers/pageHelpers'
import { useI18n } from 'core/i18n/i18nContext'
// import { useTools } from 'core/helpers/toolsContext'
import colors from 'core/theme/colors'

const Head = () => {
    const context = usePageContext()

    const { translate } = useI18n()
    // const { getToolName } = useTools()

    let overrides = {}
    // const toolName = getToolName(context)
    // if (toolName) {
    //     overrides.title = `${websiteTitle}: ${toolName}`
    // }

    const meta = getPageMeta(context, translate, overrides)
    const socialMeta = getPageSocialMeta(context, translate, overrides)
    const description = 'Check the results from survey about everything React Native!'

    const mergedMeta = [
        { charset: 'utf-8' },
        // responsive
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'custom-meta-start' },
        { name: 'description', content: description },
        // social
        ...socialMeta,
        { name: 'custom-meta-end' }
    ]

    return (
        <>
            <Helmet defaultTitle={meta.fullTitle}>
                <html lang="en" />
                <title>{meta.title}</title>
                <link rel="shortcut icon" href="/images/favicon.ico" />
                <meta name="theme-color" content={colors.link} />
                {mergedMeta.map((meta, i) => (
                    <meta {...meta} key={i} />
                ))}
            </Helmet>
        </>
    )
}

export default Head

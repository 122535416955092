import React from 'react'
import styled from 'styled-components'
import { mq } from 'core/theme'

export const SidebarLogo = () => (
    <Container>
        <img
            src={`/images/state-of-react-native-logo.png`}
            alt="RN Logo"
            width="48px"
            height="48px"
        />
        <span style={{ paddingLeft: '10px', fontWeight: 'bold', fontSize: '18px' }}>
            State of React Native 2022
        </span>
    </Container>
)

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 67px;
    .span {
        text-decoration: none;
    }
`

export default SidebarLogo
